* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  font-family: "Mulish";
}
img {
  width: 100%;
}

textarea,
select,
input {
  border: none;
  outline: none;
}


input,
textarea {
  -webkit-appearance: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type="date"]:before {
  color: rgba(33, 51, 79, 0.8);
}

input[type="date"]:after {
  color: #21334f;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-font {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
}
.form-font::placeholder {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 20px;
  color: rgba(33, 51, 79, 0.8);
}

.form-label {
  display: block;
  color: #21334f;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
}

.form-error {
  color: #ef5350;
  font-size: 1.2rem;
  display: block;
  max-width: 310px;
}
.form-btn {
  border: none;
}
.form-btn:hover,
.form-btn:focus {
  background: #34a853;
}

.black-text {
  color: #21334f;
}

.green-text {
  color: #34a853;
}

body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fs-4 {
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
}
fw-6 {
  font-family: "Mulish";
  font-weight: 600;
}
fw-4 {
  font-family: "Mulish";
  font-weight: 400;
}

img {
  width: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  height: auto;
}

.spinner {
  margin: 0 auto;
}
.btn-comp:hover, .btn-comp:focus{
  background:  #34A853;
  color:white;
  text-decoration: none;
}